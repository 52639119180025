// src/pages/ProjectForm.js
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { getProjectById, saveProject } from '../services/ProjectServices';
import { deleteStage } from '../services/StageServices';
import { getMeasureUnits } from '../services/CoreServices';
import { getCompanies } from '../services/CompanyServices';
import { MediaContext } from '../context/MediaContext';
import MediaItems from '../components/MediaItems';

import './Forms.css';
import styles from './Table.module.css';
import { getCountries, getCountryById } from '../services/CountryServices';
import { getCurrencies } from '../services/CurrencyServices';

function ProjectForm() {
  const { id } = useParams();
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const {mediaItems, setMediaItems } = useContext(MediaContext);
  const [project, setProject] = useState({ country: '', company: '', name: '', description: '', currency: '', unit: '', slug: '', mediaItems: [] });
  const [stages, setStages] = useState([]); 
  const [units, setUnits] = useState([]);
  const [updateCounter, setUpdateCounter] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setError(null);
        const countries = await getCountries();
        setCountries(countries);
        const companies = await getCompanies();
        setCompanies(companies);
        const currencies = await getCurrencies();
        setCurrencies(currencies);
        const units = await getMeasureUnits();
        setUnits(units);

        if (id) {
          const projectData = await getProjectById(id);
          const country = await getCountryById(projectData.country);
          if (country)
            setCities(country.cities);
          setProject(projectData);
          setStages(projectData.stages);
          setMediaItems(projectData.mediaItems);
        } else {
          setMediaItems([]);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id,setMediaItems,updateCounter]);

  const handleCountryChange = async (countryCode) => {
    setProject({ ...project, country: countryCode });
    const country = await getCountryById(countryCode);
    if (country)
      setCities(country.cities);
  }

  const handleDeleteStage = async (id) => {
    setError(null);
    setLoading(true);
    try {
      await deleteStage(id);
      setUpdateCounter(updateCounter+1);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleNameChange = (e) => {
    const newName = e.target.value;
    const newSlug = newName.toLowerCase().replace(/[^\w\s]/gi, '').replace(/\s+/g, '-'); // Generar slug
    setProject({ ...project, name: newName, slug: newSlug });
  };

  const handleSave = async () => {
    if (!id) return;

    try {
      project.mediaItems = mediaItems;
      project.id = id;
      await saveProject(project);  
    } catch (error) {
      console.error('Error saving project:', error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    project.mediaItems = mediaItems;
    if (id)
      project.id = id;
    try {
      await saveProject(project);
      navigate('/projects');
    } catch (error) {
      console.error('Error submitting project:', error);
    }
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="page-container">
      <div className="form-container">
        <h2>{id ? 'Editar Proyecto' : 'Agregar Proyecto'}</h2>
        {error && (
        <div>
          <div className='error-message'>
            Hubo un error: {error}
          </div>
          <br/>
        </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="project-country">País</label>
            <select
              value={project.country}
              id="project-country"
              onChange={(e) => handleCountryChange(e.target.value) }
              required
            >
              <option value="">Seleccione un país</option>
              {countries.map((country) => (
                <option key={country.code} value={country.code}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="project-city">Ciudad</label>
            <select
              value={project.city}
              id="project-city"
              onChange={(e) => setProject({...project, city: e.target.value}) }
              required
            >
              <option value="">Seleccione una ciudad</option>
              {cities.map((city) => (
                <option key={city.code} value={city.code}>
                  {city.name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="project-companyId">Compañía</label>
            <select
              value={project.companyId}
              id="project-companyId"
              onChange={(e) => setProject({ ...project, companyId: e.target.value })}
              required
            >
              <option value="">Seleccione una compañía</option>
              {companies.map((company) => (
                <option key={company.id} value={company.id}>
                  {company.name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="project-name">Nombre</label>
            <input
              type="text"
              id="project-name"
              value={project.name}
              onChange={handleNameChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="project-description">Descripción</label>
            <input
              type="text"
              id="project-description"
              value={project.description}
              onChange={(e) => setProject({ ...project, description: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="project-currency">Moneda</label>
            <select
              value={project.currency}
              id="project-currency"
              onChange={(e) => setProject({ ...project, currency: e.target.value })}
              required
            >
              <option value="">Seleccione una moneda</option>
              {currencies.map((currency) => (
                <option key={currency.code} value={currency.code}>
                  {currency.name} ({currency.symbol})
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="project-measureUnit">Unidad de Medida</label>
            <select
              value={project.measureUnit}
              id="project-measureUnit"
              onChange={(e) => setProject({ ...project, measureUnit: e.target.value })}
              required
            >
              <option value="">Seleccione una unidad</option>
              {units.map((unit) => (
                <option key={unit.code} value={unit.code}>
                  {unit.name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="project-slug">Slug (auto-generado)</label>
            <input
              type="text"
              id="project-slug"
              value={project.slug}
              readOnly
            />
          </div>

          <div className="form-group">
            <label htmlFor="project-crm">CRM Integrado</label>
            <select
              value={project.crm}
              id="project-crm"
              onChange={(e) => setProject({ ...project, crm: e.target.value })}
            >
              <option value="">Ninguno</option>
              <option value="monday">Monday</option>
              <option value="salesforce">Zoho</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="project-crmConfig">Configuración CRM</label>
            <textarea
              rows="5"
              id="project-crmConfig"
              value={project.crmConfig}
              onChange={(e) => setProject({ ...project, crmConfig: e.target.value })}
            />
          </div>

          <div className="form-group">
            <button type="submit" className="btn-submit">{id ? 'Guardar Cambios' : 'Agregar Proyecto'}</button>
          </div>
        </form>
        <div className="form-footer">
          <Link to="/projects" className="back-link">Regresar a Proyectos</Link>
        </div>
      </div>

      {project.id && (
      <div className="stages-container">        
        <MediaItems projectSlug={project.slug} objectType='project' objectSlug='main' />
        <br/>
        <button className="btn-submit" onClick={() => handleSave()}>Grabar</button>

        <h2>Etapas</h2>
        <button onClick={() => navigate('/stages/new/'+id)}>
          <FontAwesomeIcon icon={faPlus} /> {/* Ícono de "+" */}
        </button>
        <br/>
        <br/>

        <table className={styles.table}>
          <thead>
            <tr>
              <th style={{ width: '60%' }}>Nombre</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {stages.map((stage) => (
              <tr key={stage.id}>
                <td>{stage.name}</td>
                <td>
                  <button onClick={() => navigate(`/stages/edit/${id}/${stage.id}`)}>
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </button>
                  <button onClick={() => handleDeleteStage(stage.id)}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      )}
    </div>
  );
}

export default ProjectForm;
