// src/pages/AdditionalTypeForm.js
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { getAdditionalTypeById, createAdditionalType, updateAdditionalType, getGroups } from '../services/AdditionalTypeServices'; 
import { getStageById } from '../services/StageServices';
import { MediaContext } from '../context/MediaContext';
import MediaItems from '../components/MediaItems';

import './Forms.css';

function AdditionalTypeForm() {
  const { id, stageId } = useParams(); 
  const [error, setError] = useState(null);
  const [additionalType, setAdditionalType] = useState({
    code: '',
    name: '',
    description: '',
    slug: '',
    group: '',
    mediaItems: []
  });
  const [groups, setGroups] = useState([]);
  const {mediaItems, setMediaItems } = useContext(MediaContext);
  const [projectId, setProjectId] = useState('');
  const [projectSlug, setProjectSlug] = useState('');
  const [stageSlug, setStageSlug] = useState(''); 
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Obtener el slug de la etapa
        const stage = await getStageById(stageId);
        setStageSlug(stage.slug);
        setProjectSlug(stage.slug.split('-')[0]);
        setProjectId(stage.projectId);
        setGroups(getGroups());

        // Si estamos editando un tipo de adicional, obtener sus datos
        if (id) {
          const additionalTypeData = await getAdditionalTypeById(id);
          setAdditionalType(additionalTypeData);
          setMediaItems(additionalTypeData.mediaItems);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching additional type data:', error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [id, setMediaItems, stageId]);

  const handleCodeChange = (e) => {
    const newCode = e.target.value;
    const newSlug = `${stageSlug}-${newCode.toLowerCase().replace(/[^\w\s]/gi, '').replace(/\s+/g, '-')}`;
    setAdditionalType({ ...additionalType, code: newCode, slug: newSlug });
  };

  const handleSave = async () => {
    if (!id) return;

    try {
      setError(null);
      additionalType.mediaItems = mediaItems;
      await updateAdditionalType(id, additionalType);  
    } catch (error) {
      setError(error.message);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError(null);
      additionalType.mediaItems = mediaItems;
      if (id) {
        await updateAdditionalType(id, additionalType);
      } else {
        await createAdditionalType({ ...additionalType, stageId });
      }
      navigate(`/stages/edit/${projectId}/${stageId}`);
    } catch (error) {
      setError(error.message);
      console.error('Error submitting additional type:', error);
    }
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="page-container">
      <div className="form-container">
        <h2>{id ? 'Editar tipo de adicional' : 'Nuevo tipo de adicional'}</h2>
        {error && (
        <div>
          <div className='error-message'>
            Hubo un error: {error}
          </div>
          <br/>
        </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="additional-type-code">Código del tipo de adicional</label>
            <input
              type="text"
              id="additional-type-code"
              value={additionalType.code}
              onChange={handleCodeChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="additional-type-group">Grupo</label>
            <select
              value={additionalType.group}
              id="additional-type-group"
              onChange={(e) => setAdditionalType({ ...additionalType, group: e.target.value })}
              required
            >
              <option value="">Seleccione un grupo</option>
              {groups.map((group) => (
              <option key={group.code} value={group.code}>
                {group.name}
              </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="additional-type-name">Nombre</label>
            <input
              type="text"
              id="additional-type-name"
              value={additionalType.name}
              onChange={(e) => setAdditionalType({ ...additionalType, name: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="additional-type-description">Descripción</label>
            <textarea
              value={additionalType.description}
              id="additional-type-description"
              onChange={(e) => setAdditionalType({ ...additionalType, description: e.target.value })}
              rows="5"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="additional-price">Precio</label>
            <input
              type="number"
              id="additional-price"
              value={additionalType.price}
              onChange={(e) => setAdditionalType({ ...additionalType, price: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="additional-type-slug">Slug (auto-generado)</label>
            <input
              type="text"
              id="additional-type-slug"
              value={additionalType.slug}
              readOnly
            />
          </div>

          <div className="form-group">
            <button type="submit" className="btn-submit">{id ? 'Guardar Cambios' : 'Agregar tipo de adicional'}</button>
          </div>
        </form>
        <div className="form-footer">
          <Link to={`/stages/edit/${projectId}/${stageId}`} className="back-link">Regresar a Etapa</Link>
        </div>
      </div>
      {id && ( 
      <div className="stages-container">
        <MediaItems projectSlug={projectSlug} objectType='additionalType' objectSlug={additionalType.slug} />
        <br/>
        <button className="btn-submit" onClick={() => handleSave()}>Grabar</button>
      </div>
      )}
    </div>
  );
}

export default AdditionalTypeForm;
