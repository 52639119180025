// src/services/axiosConfig.js
import axios from 'axios';
import { auth } from '../firebaseConfig';
import { messages_es } from '../i18n/messages_es';

const axiosInstance = axios.create();

const getI18nMessage = (message,language='es') => {
  if (language === 'es') {
    return messages_es[message] ?? message;
  }
  return message;
}

// Interceptor de solicitud para agregar el token a todas las solicitudes
axiosInstance.interceptors.request.use(
  config => {
    const idToken = localStorage.getItem('owly-sgi-idtoken');
    if (idToken) {
      const currentTime = Math.floor(Date.now() / 1000);
      const tokenExpirationTime = JSON.parse(atob(idToken.split('.')[1])).exp;

      if (tokenExpirationTime < currentTime) {
        // Token is expired, renew it
        return auth.currentUser.getIdToken(true).then(newIdToken => {
          localStorage.setItem('owly-sgi-idtoken', newIdToken);
          config.headers.Authorization = `Bearer ${newIdToken}`;
          return config;
        }).catch(error => {
          return Promise.reject(error);
        });
      } else {
        config.headers.Authorization = `Bearer ${idToken}`;
      }
    }
    return config;
  },
  error => {
    // Aquí puedes manejar los errores sin loguearlos automáticamente
    if (error.response) {
      // Error con respuesta del servidor (status 4xx, 5xx)
      // Puedes optar por no hacer nada o manejarlo globalmente
      return Promise.reject(error);
    } else if (error.request) {
      // Error relacionado con la red, evita que Axios lo loguee
      return Promise.reject(error);
    } else {
      // Error en la configuración de la solicitud o Axios
      return Promise.reject(error);
    }
  }
);

axiosInstance.interceptors.response.use(
  response => {
    if (response.status === 200) {
      if (response.data.status === 'success') {
        return response.data.data;
      } else if (response.data.status === 'error') {
        return Promise.reject({ message: getI18nMessage(response.data.message) });
      }
    }
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
// Interceptor de respuesta para manejar el status de la respuesta
