// src/services/UnitServices.js
import axiosInstance from "./axiosConfig";

const API_URL = process.env.REACT_APP_API_URL;

// Obtener una unidad por su ID
export const getUnitById = async (id) => {
  try {
    const response = await axiosInstance.get(`${API_URL}/unit/${id}`);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Crear una nueva unidad
export const createUnit = async (unit) => {
  try {
    const response = await axiosInstance.put(`${API_URL}/unit`, unit);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Eliminar una unidad
export const deleteUnit = async (id) => {
  try {
    const response = await axiosInstance.delete(`${API_URL}/unit/${id}`);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Actualizar una unidad existente
export const updateUnit = async (id, unit) => {
  try {
    unit.id = id;
    const response = await axiosInstance.put(`${API_URL}/unit`, unit);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};
