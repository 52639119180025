// src/pages/CurrencyForm.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { getCurrencyById, saveCurrency } from '../services/CurrencyServices';
import './Forms.css';

function CurrencyForm() {
  const { id } = useParams();
  const [currency, setCurrency] = useState({ name: '', code: '', symbol: '' });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      const fetchCurrency = async () => {
        try {
          const currencyData = await getCurrencyById(id); // Usar el servicio para obtener moneda
          setCurrency(currencyData);
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };
    
      fetchCurrency();
    }
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await saveCurrency(currency); 
      navigate('/config'); 
    } catch (error) {
      setError(error.message);
    }
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="form-container">
      <h2>{id ? 'Editar Moneda' : 'Nueva Moneda'}</h2>
      {error && (
        <div>
          <div className='error-message'>
            Hubo un error: {error}
          </div>
          <br />
        </div>
      )}
      <form onSubmit={handleSubmit} className="currency-form">
        <div className="form-group">
          <label htmlFor="currency-name">Nombre</label>
          <input
            type="text"
            id="currency-name"
            value={currency.name}
            onChange={(e) => setCurrency({ ...currency, name: e.target.value })}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="currency-code">Código</label>
          <input
            type="text"
            id="currency-code"
            value={currency.code}
            onChange={(e) => setCurrency({ ...currency, code: e.target.value })}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="currency-symbol">Símbolo</label>
          <input
            type="text"
            id="currency-symbol"
            value={currency.symbol}
            onChange={(e) => setCurrency({ ...currency, symbol: e.target.value })}
          />
        </div>

        <div className="form-group">
          <button type="submit" className="btn-submit">{id ? 'Guardar Cambios' : 'Agregar Moneda'}</button>
        </div>
      </form>
      <div className="form-footer">
        <Link to="/config" className="back-link">Regresar a datos básicos</Link>
      </div>
    </div>
  );
}

export default CurrencyForm;
