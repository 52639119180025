// src/services/projectService.js
import axiosInstance from "./axiosConfig";

const API_URL = process.env.REACT_APP_API_URL;

// Eliminar un bloque
export const deleteBlock = async (id) => {
  try {
    const response = await axiosInstance.delete(`${API_URL}/block/${id}`);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Obtener un bloque por su ID
export const getBlockById = async (id) => {
  try {
    const response = await axiosInstance.get(`${API_URL}/block/${id}`);
    if (!response.mediaItems) {
      response.mediaItems = [];
    }
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Actualizar un bloque existente
export const saveBlock = async (block) => {
  try {
    const newBlock = {
      name: block.name,
      code: block.code,
      description: block.description,
      slug: block.slug,
      stageId: block.stageId,
      balconyPrice: block.balconyPrice || '0',
      buildPrice: block.buildPrice || '0',
      mezzaninePrice: block.mezzaninePrice || '0',
      terracePrice: block.terracePrice || '0',
      smlvPrice: block.smlvPrice || '0',
      mediaItems: block.mediaItems || []
    }
    if (block.id) {
      newBlock.id = block.id;
    }
    const response = await axiosInstance.put(`${API_URL}/block`, newBlock);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};
