// src/pages/StageForm.js
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { deleteBlock } from '../services/BlockServices';
import { deleteTypology } from '../services/TypologyServices';
import { getHousingTypologies } from '../services/HousingTypologyServices';
import { getStageById, saveStage } from '../services/StageServices'; 
import { getProjectById } from '../services/ProjectServices';
import { MediaContext } from '../context/MediaContext';
import MediaItems from '../components/MediaItems';

import './Forms.css';
import styles from './Table.module.css';

function StageForm() {
  const { id, projectId } = useParams(); 

  const [additionalTypes, setAdditionalTypes] = useState([]); 
  const [blocks, setBlocks] = useState([]); 
  const [error, setError] = useState(null);
  const [housingTypologies, setHousingTypologies] = useState([]);
  const [loading, setLoading] = useState(true);
  const {mediaItems, setMediaItems } = useContext(MediaContext);
  const [projectName, setProjectName] = useState(''); 
  const [projectSlug, setProjectSlug] = useState(''); 
  const [stage, setStage] = useState({ name: '', description: '', stageNumber: '', closingDate: '', slug: '', housingTypology: '', discount: '', downPayment: '', separation: '', projectedIncrease: '', mediaItems: [] });
  const [typologies, setTypologies] = useState([]); 
  const [updateCounter, setUpdateCounter] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Obtener el nombre del proyecto
        const project = await getProjectById(projectId);
        setProjectName(project.name);
        setProjectSlug(project.slug);
        const lista = await getHousingTypologies();
        setHousingTypologies(lista);

        // Si estamos editando una etapa, obtener sus datos
        if (id) {
          const stageData = await getStageById(id);
          setStage(stageData);
          setBlocks(stageData.blocks);
          setTypologies(stageData.typologies);
          setAdditionalTypes(stageData.additionalTypes);
          setMediaItems(stageData.mediaItems);
        } else {
          setMediaItems([]);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, projectId, setMediaItems, updateCounter]);

  const handleDeleteBlock = async (id) => {
    setError(null);
    setLoading(true);
    try {
      await deleteBlock(id);
      setUpdateCounter(updateCounter + 1);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteTypology = async (id) => {
    setError(null);
    setLoading(true);
    try {
      await deleteTypology(id);
      setUpdateCounter(updateCounter + 1);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    if (!id) return;

    try {
      stage.id = id;
      stage.mediaItems = mediaItems;
      stage.projectId = projectId;
      await saveStage(stage);  
    } catch (error) {
      setError(error.message);
    }
  }

  const handleStageNumberChange = (e) => {
    const newStageNumber = e.target.value;
    const newSlug = `${projectName.toLowerCase().replace(/[^\w\s]/gi, '').replace(/\s+/g, '-')}-${newStageNumber}`;
    setStage({ ...stage, stageNumber: newStageNumber, slug: newSlug });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      stage.mediaItems = mediaItems;
      if (id) {
        stage.id = id;
      }
      stage.projectId = projectId;
      await saveStage(stage);
      navigate(`/projects/edit/${projectId}`);
    } catch (error) {
      setError(error.message);
    }
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="page-container">
      <div className="form-container">
        <h2>{id ? 'Editar Etapa' : 'Nueva Etapa'}</h2>
        {error && (
        <div>
          <div className='error-message'>
            Hubo un error: {error}
          </div>
          <br/>
        </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="stage-name">Nombre de la Etapa</label>
            <input
              type="text"
              id="stage-name"
              value={stage.name}
              onChange={(e) => setStage({ ...stage, name: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="stage-description">Descripción</label>
            <textarea
              id="stage-description"
              value={stage.description}
              onChange={(e) => setStage({ ...stage, description: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="stage-stageNumber">Número de Etapa</label>
            <input
              type="number"
              id="stage-stageNumber"
              value={stage.stageNumber}
              onChange={handleStageNumberChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="stage-closingDate">Fecha de Entrega</label>
            <input
              type="date"
              id="stage-closingDate"
              value={stage.closingDate}
              onChange={(e) => setStage({ ...stage, closingDate: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="stage-housingTypology">Tipo de proyecto</label>
            <select
              id="stage-housingTypology"
              value={stage.housingTypology}
              onChange={(e) => setStage({ ...stage, housingTypology: e.target.value })}
            >
              <option value="">Selecciona una tipología de vivienda</option>
              {housingTypologies.map((housingTypology) => (
                <option key={housingTypology.id} value={housingTypology.code}>
                  {housingTypology.name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="stage-discount">Porcentaje de Descuento</label>
            <input
              type="number"
              id="stage-discount"
              value={stage.discount}
              onChange={(e) => setStage({ ...stage, discount: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="stage-downPayment">Porcentaje de cuota inicial</label>
            <input
              type="number"
              id="stage-downPayment"
              value={stage.downPayment}
              onChange={(e) => setStage({ ...stage, downPayment: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="stage-separation">Porcentaje de separación</label>
            <input
              type="number"
              id="stage-separation"
              value={stage.separation}
              onChange={(e) => setStage({ ...stage, separation: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="stage-separation">Incremento esperado a la entrega (%)</label>
            <input
              type="number"
              id="stage-projectedIncrease"
              value={stage.projectedIncrease}
              onChange={(e) => setStage({ ...stage, projectedIncrease: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="stage-slug">Slug (auto-generado)</label>
            <input
              id="stage-slug"
              type="text"
              value={stage.slug}
              readOnly
            />
          </div>

          <div className="form-group">
            <button type="submit" className="btn-submit">{id ? 'Guardar Cambios' : 'Agregar Etapa'}</button>
          </div>
        </form>
        <div className="form-footer">
          <Link to={`/projects/edit/${projectId}`} className="back-link">Regresar al Proyecto</Link>
        </div>
      </div>
      {id && (
      <div className="stages-container">
        <MediaItems projectSlug={projectSlug} objectType='stage' objectSlug={stage.slug} />
        <br/>
        <button className="btn-submit" onClick={() => handleSave()}>Grabar</button>

        <h2>Bloques</h2>
        <button onClick={() => navigate('/blocks/new/'+id)}>
          <FontAwesomeIcon icon={faPlus} /> {/* Ícono de "+" */}
        </button>
        <br/>
        <br/>

        <table className={styles.table}>
          <thead>
            <tr>
              <th style={{ width: '60%' }}>Nombre</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {blocks.map((block) => (
              <tr key={block.id}>
                <td>{block.name}</td>
                <td>
                  <button onClick={() => navigate(`/blocks/edit/${id}/${block.id}`)}>
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </button>
                  <button onClick={() => handleDeleteBlock(block.id)}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <br/>
        <br/>
        <h2>Tipologías</h2>
        <button onClick={() => navigate('/typologies/new/'+id)}>
          <FontAwesomeIcon icon={faPlus} /> {/* Ícono de "+" */}
        </button>
        <br/>
        <br/>

        <table className={styles.table}>
          <thead>
            <tr>
              <th style={{ width: '10%' }}>Código</th>
              <th style={{ width: '60%' }}>Nombre</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {typologies.map((typology) => (
              <tr key={typology.id}>
                <td>{typology.code}</td>
                <td>{typology.name}</td>
                <td>
                  <button onClick={() => navigate(`/typologies/edit/${id}/${typology.id}`)}>
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </button>
                  <button onClick={() => handleDeleteTypology(typology.id)}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <h2>Tipos de adicionales</h2>
        <button onClick={() => navigate('/additionalTypes/new/'+id)}>
          <FontAwesomeIcon icon={faPlus} /> {/* Ícono de "+" */}
        </button>
        <br/>
        <br/>

        <table className={styles.table}>
          <thead>
            <tr>
              <th style={{ width: '10%' }}>Código</th>
              <th style={{ width: '60%' }}>Nombre</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {additionalTypes.map((additionalType) => (
              <tr key={additionalType.id}>
                <td>{additionalType.code}</td>
                <td>{additionalType.name}</td>
                <td>
                  <button onClick={() => navigate(`/additionalTypes/edit/${id}/${additionalType.id}`)}>
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </button>
                  <button onClick={() => handleDeleteTypology(additionalType.id)}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>
      )}
    </div>
  );
}

export default StageForm;
