// src/pages/CityForm.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { getCityById, saveCity } from '../services/CityServices';
import './Forms.css';
import { getCountryById } from '../services/CountryServices';

function CityForm() {
  const { id, countryId } = useParams();
  const [city, setCity] = useState({ name: '', code: '', slug: '', countryId: countryId });
  const [country, setCountry ] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCity = async () => {
      try {
        if (id) {
          const cityData = await getCityById(id);
          setCity(cityData);
        };
        const countryData = await getCountryById(countryId);
        setCountry(countryData);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchCity();
  }, [id,countryId]);

  const handleCodeChange = (e) => {
    const newCode = e.target.value;
    const newSlug = `${country.code}-${newCode.toLowerCase().replace(/[^\w\s]/gi, '').replace(/\s+/g, '-')}`;
    setCity({ ...city, code: newCode, slug: newSlug });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await saveCity(city); 
      navigate('/countries/edit/' + countryId);
    } catch (error) {
      setError(error.message);
    }
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="form-container">
      {error && (
      <div>
        <div className='error-message'>
          Hubo un error: {error}
        </div>
        <br/>
      </div>
      )}
      <h2>{id ? 'Editar Ciudad' : 'Nueva Ciudad'}</h2>
      <form onSubmit={handleSubmit} className="city-form">
        <div className="form-group">
          <label htmlFor="city-name">Nombre</label>
          <input
            type="text"
            id="city-name"
            value={city.name}
            onChange={(e) => setCity({ ...city, name: e.target.value })}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="city-code">Código</label>
          <input
            type="text"
            id="city-code"
            value={city.code}
            onChange={handleCodeChange}
            required
          />
        </div>

        <div className="form-group">
            <label htmlFor="city-slug">Slug (auto-generado)</label>
            <input
              type="text"
              id="city-slug"
              value={city.slug}
              readOnly
            />
        </div>

        <div className="form-group">
          <button type="submit" className="btn-submit">{id ? 'Guardar Cambios' : 'Agregar Ciudad'}</button>
        </div>
      </form>
      <div className="form-footer">
        <Link to={`/countries/edit/${countryId}`} className="back-link">Regresar a { country.name }</Link>
      </div>
    </div>
  );
}

export default CityForm;
