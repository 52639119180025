import React, { useEffect } from 'react';
import { getLeads } from '../services/LeadServices';
import { getQuotes } from '../services/QuoteServices';

const ProjectUnits = ({ project, units, unit }) => {
    const [activeTab, setActiveTab] = React.useState('units');
    const [leads, setLeads] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [quotes, setQuotes] = React.useState([]);

    const formatDate = (date) => {
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Date(date).toLocaleDateString('es-ES', options).replace(/ de /g, '-').toUpperCase();
    }

    const formatValue = (value) => {
        return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
    }

    const getUnit = (quote) => {
        const unit = units.find((unit) => unit.id === quote.unitId);
        return unit ? unit.code : 'Unidad no encontrada';
    }

    const renderContent = () => {
        switch (activeTab) {
            case 'units':
                return (
                    <div>
                        <div className="type-card header">
                            <h5>Unidad</h5>
                            <h5>Área</h5>
                            <h5>Piso</h5>
                            <h5>Precio</h5>
                            <h5>Estado</h5>
                        </div>
                        {units.map((unit) => (
                            <div className="type-card" key={unit.id}>
                                <div>{unit.code}</div>
                                <div>{unit.buildArea+" "+project.measureUnit}</div>
                                <div>{unit.floor}</div>
                                <div>{unit.price}</div>
                                <div><span className={`status ${unit.status}`}></span></div>
                            </div>
                        ))}
                    </div>
                );
            case 'leads':
                return (
                    <div>
                        <div className="lead-card header">
                            <h5>Nombre</h5>
                            <h5>Teléfono</h5>
                            <h5>Email</h5>
                        </div>
                        {leads.map((lead) => (
                            <div className="lead-card" key={lead.id}>
                                <div>{lead.firstName+" "+lead.lastName}</div>
                                <div>{lead.phone}</div>
                                <div>{lead.email}</div>
                            </div>
                        ))}
                    </div>
                );
            case 'quotes':
                return <div>
                    <div className="quote-card header">
                        <h5>Lead</h5>
                        <h5>Unidad</h5>
                        <h5>Fecha</h5>
                        <h5>Valor</h5>
                        <h5>Vis.</h5>
                    </div>
                    {quotes.map((quote) => (
                        <div className="quote-card" key={quote.id}>
                            <div>{quote.leadName}</div>
                            <div>{getUnit(quote)}</div>
                            <div>{formatDate(quote.updatedAt)}</div>
                            <div>{formatValue(quote.total)}</div>
                            <div>{quote.queries}</div>
                        </div>
                    ))}
                </div>
            case 'data':
                let typologyCode = '';
                if (unit) {
                    typologyCode = unit.typologySlug.split('-').pop();
                }
                return (
                    <div>
                        {unit ? (
                            <div>
                                <h2>
                                    {unit.code + " " + typologyCode}
                                    <span className={`status ${unit.status}`}></span>
                                </h2>
                                <table style={{ width: '100%' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '60%' }}>Área de construcción:</td>
                                            <td>{unit.buildArea + " " + project.measureUnit}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: '60%' }}>Área privada:</td>
                                            <td>{unit.privateArea + " " + project.measureUnit}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: '60%' }}>Área de balcón:</td>
                                            <td>{unit.balconyArea + " " + project.measureUnit}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: '60%' }}>Área de terraza:</td>
                                            <td>{unit.terraceArea + " " + project.measureUnit}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: '60%' }}>Área privada de terraza:</td>
                                            <td>{unit.terracePrivateArea + " " + project.measureUnit}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: '60%' }}>Área de mezzanine:</td>
                                            <td>{unit.mezzanineArea + " " + project.measureUnit}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: '60%' }}>Piso:</td>
                                            <td>{unit.floor}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: '60%' }}>Habitaciones:</td>
                                            <td>{unit.bedrooms}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: '60%' }}>Baños:</td>
                                            <td>{unit.bathrooms}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: '60%' }}>Precio:</td>
                                            <td>{unit.price}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <h2>No hay una unidad seleccionada</h2>
                        )}
                    </div>
                );
            default:
                return null;
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const leads = await getLeads(project.id);
                setLeads(leads);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching project data:', error);
            }
        };

        fetchData();
    }, [project]);

    useEffect(() => {
        const filterQuotes = (quotes) => {
            return quotes.filter((quote) => {
                const unit = units.find((unit) => unit.id === quote.unitId);
                if (unit) {
                    quote.unitCode = unit.code;
                    return true;
                }
                return false;
            });
        }
    
        const fetchData = async () => {
            const quotes = filterQuotes(await getQuotes(project.id));
            setQuotes(quotes);
        };

        fetchData();
    }, [project.id,units]);

    useEffect(() => {
        if (unit) {
            setActiveTab('data');
        } else {
            setActiveTab('units');
        }
    }, [unit]);

    if (loading) {
        return <div>Cargando...</div>;
    }

    return (
        <div>
            <div className="tabs">
                <button
                    className={activeTab === 'units' ? 'active' : ''}
                    onClick={() => setActiveTab('units')}
                >
                    Unidades
                </button>
                <button 
                    className={activeTab === 'leads' ? 'active' : ''}
                    onClick={() => setActiveTab('leads')}
                >
                    Leads
                </button>
                <button 
                    className={activeTab === 'quotes' ? 'active' : ''}
                    onClick={() => setActiveTab('quotes')}
                >
                        Cotizaciones
                </button>
                <button 
                    className={activeTab === 'data' ? 'active' : ''}
                    onClick={() => setActiveTab('data')}
                >
                    Unidad
                </button>
            </div>
            <div className="tab-content-2">
                {renderContent()}
            </div>
        </div>
    );
};

export default ProjectUnits;