// src/services/AdditionalTypeServices.js
import axiosInstance from "./axiosConfig";

const API_URL = process.env.REACT_APP_API_URL;

// Crear un nuevo tipo adicional
export const createAdditionalType = async (additionalType) => {
  try {
    const response = await axiosInstance.put(`${API_URL}/additionalType`, additionalType);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Eliminar un tipo adicional
export const deleteAdditionalType = async (id) => {
  try {
    const response = await axiosInstance.delete(`${API_URL}/additionalType/${id}`);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Obtener un tipo adicional por su ID
export const getAdditionalTypeById = async (id) => {
  try {
    const response = await axiosInstance.get(`${API_URL}/additionalType/${id}`);
    if (!response.mediaItems)
      response.mediaItems = [];
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getAdditionalTypesByStageId = async (stageId) => {
  try {
    const response = await axiosInstance.get(`${API_URL}/additionalTypes/${stageId}`);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
}

export const getGroups = () => {
  return [
    {
      code: 'PARK',
      name: "Estacionamiento"
    },
    {
      code: 'UTIL',
      name: "Cuarto útil"
    }
  ];
}

// Actualizar un tipo adicional existente
export const updateAdditionalType = async (id, additionalType) => {
  try {
    additionalType.id = id;
    const response = await axiosInstance.put(`${API_URL}/additionalType`, additionalType);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};
