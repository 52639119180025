// src/services/countryService.js
import axiosInstance from "./axiosConfig";

const API_URL = process.env.REACT_APP_API_CORE_URL;

export const getCountries = async () => {
  try {
    const response = await axiosInstance.get(`${API_URL}/countries`);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Obtener un país por su ID
export const getCountryById = async (id) => {
  try {
    const response = await axiosInstance.get(`${API_URL}/country/${id}`);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Eliminar un país
export const deleteCountry = async (id) => {
  try {
    const response = await axiosInstance.delete(`${API_URL}/country/${id}`);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Crear un nuevo país
export const saveCountry = async (country) => {
  try {
    const response = await axiosInstance.put(`${API_URL}/country`, country);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};
