// src/services/currencyService.js
import axiosInstance from "./axiosConfig";

const API_URL = process.env.REACT_APP_API_CORE_URL;

export const getCurrencies = async () => {
  try {
    const response = await axiosInstance.get(`${API_URL}/currencies`);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Obtener una moneda por su ID
export const getCurrencyById = async (id) => {
  try {
    const response = await axiosInstance.get(`${API_URL}/currency/${id}`);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Eliminar una moneda
export const deleteCurrency = async (id) => {
  try {
    const response = await axiosInstance.delete(`${API_URL}/currency/${id}`);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Crear una nueva moneda
export const saveCurrency = async (currency) => {
  try {
    const response = await axiosInstance.put(`${API_URL}/currency`, currency);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};
