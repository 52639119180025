// src/pages/HousingTypologyForm.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { getHousingTypologyById, saveHousingTypology } from '../services/HousingTypologyServices';
import './Forms.css';

function HousingTypologyForm() {
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [housingTypology, setHousingTypology] = useState({ name: '', code: '', priceCriteria: '', maxPrice: 0, allowSubsidy: false });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      const fetchHousingTypology = async () => {
        try {
          const housingTypologyData = await getHousingTypologyById(id); // Usar el servicio para obtener tipología de vivienda
          setHousingTypology(housingTypologyData);
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };
    
      fetchHousingTypology();
    }
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await saveHousingTypology(housingTypology); 
      navigate('/config'); 
    } catch (error) {
      console.error('Error submitting housing typology:', error);
    }
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="form-container">
      <h2>{id ? 'Editar Tipología de Vivienda' : 'Nueva Tipología de Vivienda'}</h2>
      {error && (
        <div>
          <div className='error-message'>
            Hubo un error: {error}
          </div>
          <br />
        </div>
      )}
      <form onSubmit={handleSubmit} className="housing-typology-form">
        <div className="form-group">
          <label htmlFor="housing-typology-name">Nombre</label>
          <input
            type="text"
            id="housing-typology-name"
            value={housingTypology.name}
            onChange={(e) => setHousingTypology({ ...housingTypology, name: e.target.value })}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="housing-typology-code">Código</label>
          <input
            type="text"
            id="housing-typology-code"
            value={housingTypology.code}
            onChange={(e) => setHousingTypology({ ...housingTypology, code: e.target.value })}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="housing-typology-priceCriteria">Criterio de precio</label>
          <select
            id="housing-typology-priceCriteria"
            value={housingTypology.priceCriteria}
            onChange={(e) => setHousingTypology({ ...housingTypology, priceCriteria: e.target.value })}
            required
          >
            <option value="">Seleccione una opción</option>
            <option value="area">Por área</option>
            <option value="smlv">Límite en SMLV</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="housing-typology-maxPrice">Precio máximo (SMLV)</label>
          <input
            type="number"
            step="0.01"
            id="housing-typology-maxPrice"
            value={housingTypology.maxPrice}
            onChange={(e) => setHousingTypology({ ...housingTypology, maxPrice: e.target.value })}
          />
        </div>

        <div className="form-group">
          <label htmlFor="housing-typology-allowSubsidy">¿Permite aplicar a un plan de subsidio?</label>
          <input
            type="checkbox"
            id="housing-typology-allowSubsidy"
            checked={housingTypology.allowSubsidy}
            onChange={(e) => setHousingTypology({ ...housingTypology, allowSubsidy: e.target.checked })}
          />
        </div>

        <div className="form-group">
          <label htmlFor="housing-typology-interestRate">Tasa de interés estimada</label>
          <input
            type="number"
            step="0.01"
            id="housing-typology-interestRate"
            value={housingTypology.interestRate}
            onChange={(e) => setHousingTypology({ ...housingTypology, interestRate: e.target.value })}
          />
        </div>

        <div className="form-group">
          <label htmlFor="housing-typology-loanTerm">Plazo de crédito estimado (años)</label>
          <input
            type="number"
            step="1"
            id="housing-typology-loanTerm"
            value={housingTypology.loanTerm}
            onChange={(e) => setHousingTypology({ ...housingTypology, loanTerm: e.target.value })}
          />
        </div>

        <button type="submit" className="btn-submit">{id ? 'Guardar Cambios' : 'Agregar Tipología de Vivienda'}</button>
      </form>
      <div className="form-footer">
        <Link to="/config" className="back-link">Regresar a datos básicos</Link>
      </div>
    </div>
  );
}

export default HousingTypologyForm;
