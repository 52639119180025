// src/services/companyService.js
import axiosInstance from "./axiosConfig";

const API_URL = process.env.REACT_APP_API_URL;

export const getCompanies = async () => {
  try {
    const response = await axiosInstance.get(`${API_URL}/company`);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Obtener una compañía por su ID
export const getCompanyById = async (id) => {
  try {
    const response = await axiosInstance.get(`${API_URL}/company/${id}`);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Crear una nueva compañía
export const createCompany = async (company) => {
  try {
    const response = await axiosInstance.put(`${API_URL}/company`, company);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Actualizar una compañía existente
export const updateCompany = async (id, company) => {
  try {
    company.id = id;
    const response = await axiosInstance.put(`${API_URL}/company`, company);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Eliminar una compañía
export const deleteCompany = async (id) => {
  try {
    const response = await axiosInstance.delete(`${API_URL}/company/${id}`);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};
