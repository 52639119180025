// src/services/projectService.js
import axiosInstance from "./axiosConfig";

const API_URL = process.env.REACT_APP_API_URL;

// Eliminar un proyecto
export const deleteStage = async (id) => {
  try {
    const response = await axiosInstance.delete(`${API_URL}/stage/${id}`);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Obtener un proyecto por su ID
export const getStageById = async (id) => {
  try {
    const response = await axiosInstance.get(`${API_URL}/stage/${id}`);
    if (!response.mediaItems) {
      response.mediaItems = [];
    }
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Actualizar un proyecto existente
export const saveStage = async (stage) => {
  try {
    const newStage = {
      name: stage.name,
      description: stage.description,
      stageNumber: stage.stageNumber,
      closingDate: stage.closingDate,
      housingTypology: stage.housingTypology,
      discount: stage.discount,
      downPayment: stage.downPayment,
      projectId: stage.projectId,
      separation: stage.separation,
      projectedIncrease: stage.projectedIncrease,
      slug: stage.slug,
      mediaItems: stage.mediaItems || []
    };
    if (stage.id) {
      newStage.id = stage.id;
    }
    const response = await axiosInstance.put(`${API_URL}/stage`, newStage);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

