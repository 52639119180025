// src/services/projectService.js
import axiosInstance from "./axiosConfig";

const API_URL = process.env.REACT_APP_API_URL;

export const getProjects = async () => {
  try {
    const response = await axiosInstance.get(`${API_URL}/project`);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Obtener un proyecto por su ID
export const getProjectById = async (id) => {
  try {
    const response = await axiosInstance.get(`${API_URL}/project/${id}`);
    if (!response.mediaItems) {
      response.mediaItems = [];
    }
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Crear un nuevo proyecto
export const saveProject = async (project) => {
  try {
    const newProject = {
      city: project.city,
      companyId: project.companyId,
      country: project.country,
      crm: project.crm,
      crmConfig: project.crmConfig,
      currency: project.currency,
      description: project.description,
      measureUnit: project.measureUnit,
      mediaItems: project.mediaItems || [],
      name: project.name,
      slug: project.slug
    };
    if (project.id) {
      newProject.id = project.id;
    }

    const response = await axiosInstance.put(`${API_URL}/project`, newProject);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Eliminar un proyecto
export const deleteProject = async (id) => {
  try {
    const response = await axiosInstance.delete(`${API_URL}/project/${id}`);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};
