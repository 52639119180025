// src/pages/TypologyForm.js
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { getTypologyById, saveTypology } from '../services/TypologyServices'; 
import { getProjectById } from '../services/ProjectServices';
import { getStageById } from '../services/StageServices';
import { MediaContext } from '../context/MediaContext';
import MediaItems from '../components/MediaItems';

import './Forms.css';

function TypologyForm() {
  const { id, stageId } = useParams(); 
  const [error, setError] = useState(null);
  const [typology, setTypology] = useState({
    code: '',
    name: '',
    slug: '',
    bedrooms: '',
    bathrooms: '',
    mediaItems: []
  });
  const {mediaItems, setMediaItems } = useContext(MediaContext);
  const [projectId, setProjectId] = useState('');
  const [projectSlug, setProjectSlug] = useState('');
  const [stageSlug, setStageSlug] = useState(''); 
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Obtener el slug de la etapa
        const stage = await getStageById(stageId);
        setStageSlug(stage.slug);
        setProjectId(stage.projectId);
        const project = await getProjectById(stage.projectId);
        setProjectSlug(project.slug);

        // Si estamos editando una tipología, obtener sus datos
        if (id) {
          const typologyData = await getTypologyById(id);
          setTypology(typologyData);
          setMediaItems(typologyData.mediaItems);
        } else {
          setMediaItems([]);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }

    };

    fetchData();
  }, [id, setMediaItems, stageId]);

  const handleCodeChange = (e) => {
    const newCode = e.target.value;
    const newSlug = `${stageSlug}-${newCode.toLowerCase().replace(/[^\w\s]/gi, '').replace(/\s+/g, '-')}`;
    setTypology({ ...typology, code: newCode, slug: newSlug });
  };

  const handleSave = async () => {
    if (!id) return;

    try {
      setLoading(true);
      setError(null);
      typology.mediaItems = mediaItems;
      typology.stageId = stageId;
      typology.id = id;
      await saveTypology(typology);  
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError(null);
      setLoading(true);
      typology.mediaItems = mediaItems;
      typology.stageId = stageId;
      if (id) {
        typology.id = id;
      }
      await saveTypology(typology);
      navigate(`/stages/edit/${projectId}/${stageId}`);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="page-container">
      <div className="form-container">
        <h2>{id ? 'Editar Tipología' : 'Nueva Tipología'}</h2>
        {error && (
        <div>
          <div className='error-message'>
            Hubo un error: {error}
          </div>
          <br/>
        </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="typology-code">Código de la Tipología</label>
            <input
              type="text"
              id="typology-code"
              value={typology.code}
              onChange={handleCodeChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="typology-name">Nombre</label>
            <input
              type="text"
              id="typology-name"
              value={typology.name}
              onChange={(e) => setTypology({ ...typology, name: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="typology-bedrooms">Dormitorios</label>
            <input
              type="number"
              id="typology-bedrooms"
              value={typology.bedrooms}
              onChange={(e) => setTypology({ ...typology, bedrooms: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="typology-bathrooms">Baños</label>
            <input
              type="number"
              id="typology-bathrooms"
              value={typology.bathrooms}
              onChange={(e) => setTypology({ ...typology, bathrooms: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="typology-targetFrame">Frame en rotación 360°</label>
            <input
              type="number"
              id="typology-targetFrame"
              value={typology.targetFrame}
              onChange={(e) => setTypology({ ...typology, targetFrame: e.target.value })}
            />
          </div>

          <div className="form-group">
            <label htmlFor="typology-slug">Slug (auto-generado)</label>
            <input
              type="text"
              id="typology-slug"
              value={typology.slug}
              readOnly
            />
          </div>

          <div className="form-group">
            <button type="submit" className="btn-submit">{id ? 'Guardar Cambios' : 'Agregar Tipología'}</button>
          </div>
        </form>
        <div className="form-footer">
          <Link to={`/stages/edit/${projectId}/${stageId}`} className="back-link">Regresar a Etapa</Link>
        </div>
      </div>
      {id && ( 
      <div className="stages-container">
        <MediaItems projectSlug={projectSlug} objectType='typology' objectSlug={typology.slug} />
        <br/>
        <button className="btn-submit" onClick={() => handleSave()}>Grabar</button>
      </div>
      )}
    </div>
  );
}

export default TypologyForm;
