// src/pages/Vendedor.js
import React, { useEffect, useMemo, useState } from 'react';
import { getProjectById, getProjects } from '../services/ProjectServices';
import ProjectFilters from '../components/ProjectFilters';
import ProjectUnits from '../components/ProjectUnits';
import './Salesperson.css';
import { getMediaUrl } from '../services/CoreServices';

function Salesperson() {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [projectList, setProjectList] = useState([]);
  const [rotatorUrl, setRotatorUrl] = useState(null);
  const [unit, setUnit] = useState(null);
  const [units, setUnits] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const projects = await getProjects();
        setProjectList(projects);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSelectProject = async (project) => {
    setLoading(true);
    try {
      setError(null);
      const p = await getProjectById(project.id);
      setProject(p);
      const url = getMediaUrl(p,'rotator-1');
      setRotatorUrl(url);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  const handleUnitsChange = (units) => {
    setUnits(units);
    setUnit(null);
  }

  const handleUnitChange = (unit) => {
    setUnit(unit);
  }

  const memoizedImageUrls = useMemo(() => {
    return projectList.reduce((acc, proj) => {
      acc[proj.id] = getMediaUrl(proj, 'image-1');
      return acc;
    }, {});
  }, [projectList]);

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div>
      {error && (
      <div>
        <div className='error-message'>
          Hubo un error: {error}
        </div>
        <br/>
      </div>
      )}
      {project && (
        <div>
          <div className="header-row project-title">
            <button onClick={() => setProject(null)}>&larr; Regresar</button>
            <div className="project-name">{project.name}</div>
          </div>
          <div className="layout">
            <div className="left-side">
              <div className="card">
                {rotatorUrl && (
                <iframe
                  id="project-iframe"
                  title="project"
                  loading="lazy"
                  allowFullScreen=""
                  height="100%"
                  width="100%"
                  src={rotatorUrl}>
                </iframe>
                )}
              </div>
              <div className="card">
                <ProjectUnits project={project} units={units} unit={unit} />
              </div>
            </div>
            <div className="right-side" style={{ height: '100%' }}>
              <div className="card frame-row">
                <ProjectFilters project={project} onUnitsChange={handleUnitsChange} onUnitChange={handleUnitChange} />
              </div>
              <div className="card"></div>
            </div>
          </div>
        </div>
      )}
      {!project && (
        <div>
          <h2>Seleccione proyecto</h2>          
            <div className="project-list">
            {projectList.map((proj) => (
              <div key={proj.id} className="project-card" onClick={() => handleSelectProject(proj)}>
                <h3>{proj.name}</h3>
                <img src={memoizedImageUrls[proj.id]} alt={proj.name} />
              </div>
            ))}
            </div>
        </div>
      )}
    </div>
  );
}

export default Salesperson;
