// src/pages/CountryForm.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { getCountryById, saveCountry } from '../services/CountryServices';
import { deleteCity } from '../services/CityServices';

import './Forms.css';
import styles from './Table.module.css';

function CountryForm() {
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [country, setCountry] = useState({ code: '', name: '' });
  const [cities, setCities] = useState([]);
  const [updateCounter, setUpdateCounter] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setError(null);

        if (id) {
          const countryData = await getCountryById(id);
          setCountry(countryData);
          setCities(countryData.cities);
        }
      } catch (error) {
        setError(error.message);
        console.error('Error fetching country data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, updateCounter]);

  const handleDeleteCity = async (id) => {
    setError(null);
    setLoading(true);
    try {
      await deleteCity(id);
      setUpdateCounter(updateCounter + 1);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) country.id = id;
      await saveCountry(country);
      navigate('/config');
    } catch (error) {
      setError(error.message);
    }
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="page-container">
      <div className="form-container">
        <h2>{id ? 'Editar País' : 'Agregar País'}</h2>
        {error && (
          <div>
            <div className='error-message'>
              Hubo un error: {error}
            </div>
            <br />
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="country-code">Código</label>
            <input
              type="text"
              id="country-code"
              value={country.code}
              onChange={(e) => setCountry({ ...country, code: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="country-name">Nombre</label>
            <input
              type="text"
              id="country-name"
              value={country.name}
              onChange={(e) => setCountry({ ...country, name: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <button type="submit" className="btn-submit">{id ? 'Guardar Cambios' : 'Agregar País'}</button>
          </div>
        </form>
        <div className="form-footer">
          <Link to="/config" className="back-link">Regresar a datos básicos</Link>
        </div>
      </div>

      {country.id && (
        <div className="stages-container">
          <h2>Ciudades</h2>
          <button onClick={() => navigate('/cities/new/' + id)}>
            <FontAwesomeIcon icon={faPlus} /> {/* Ícono de "+" */}
          </button>
          <br />
          <br />

          <table className={styles.table}>
            <thead>
              <tr>
                <th style={{ width: '60%' }}>Nombre</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {cities.map((city) => (
                <tr key={city.id}>
                  <td>{city.name}</td>
                  <td>
                    <button onClick={() => navigate(`/cities/edit/${id}/${city.id}`)}>
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </button>
                    <button onClick={() => handleDeleteCity(city.id)}>
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default CountryForm;
