import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { getConfig, saveConfig } from '../services/ConfigServices';

import './Forms.css';
import styles from './Table.module.css';
import { deleteCountry, getCountries } from '../services/CountryServices';
import { deleteCurrency, getCurrencies } from '../services/CurrencyServices';
import { deleteSubsidyPlan, getSubsidyPlans } from '../services/SubsidyPlanServices';
import { deleteHousingTypology, getHousingTypologies } from '../services/HousingTypologyServices';

function ConfigForm() {
  const { id } = useParams();
  const [config, setConfig] = useState({ smlv: 0 });
  const [countries, setCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [error, setError] = useState(null);
  const [housingTypologies, setHousingTypologies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [subsidyPlans, setSubsidyPlans] = useState([]);
  const [updateCounter, setUpdateCounter] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setError(null);
        const countriesList = await getCountries();
        setCountries(countriesList);
        const currenciesList = await getCurrencies();
        setCurrencies(currenciesList);
        const subsidyPlansList = await getSubsidyPlans();
        setSubsidyPlans(subsidyPlansList);
        const housingTypologiesList = await getHousingTypologies();
        setHousingTypologies(housingTypologiesList);

        const configData = await getConfig();
        if (!configData || configData.smlv === undefined) {
          setConfig({ smlv: 0 });
        } else {
          setConfig(configData);
        }
      } catch (error) {
        setError(error.message);
        console.error('Error fetching config data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, updateCounter]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await saveConfig(config);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleDeleteCountry = async (countryId) => {
    setError(null);
    setLoading(true);
    try {
      await deleteCountry(countryId);
      setUpdateCounter(updateCounter + 1);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCurrency = async (currencyId) => {
    setError(null);
    setLoading(true);
    try {
      await deleteCurrency(currencyId);
      setUpdateCounter(updateCounter + 1);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteSubsidyPlan = async (subsidyPlanId) => {
    setError(null);
    setLoading(true);
    try {
      await deleteSubsidyPlan(subsidyPlanId);
      setUpdateCounter(updateCounter + 1);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteHousingTypology = async (housingTypologyId) => {
    setError(null);
    setLoading(true);
    try {
      await deleteHousingTypology(housingTypologyId);
      setUpdateCounter(updateCounter + 1);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="page-container">
      <div className="form-container">
        <h2>{'Editar Configuración'}</h2>
        {error && (
          <div>
            <div className='error-message'>
              Hubo un error: {error}
            </div>
            <br />
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="config-smlv">Valor de Salario Mínimo en Colombia (SMLV)</label>
            <input
              type="number"
              id="config-smlv"
              value={config.smlv}
              onChange={(e) => setConfig({ ...config, smlv: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <button type="submit" className="btn-submit">Guardar Cambios</button>
          </div>
        </form>
      </div>

      <div className="stages-container">
        <h2>Países</h2>
        <button onClick={() => navigate('/countries/new')}>
          <FontAwesomeIcon icon={faPlus} />
        </button>
        <br />
        <br />

        <table className={styles.table}>
          <thead>
            <tr>
              <th style={{ width: '60%' }}>Nombre</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {countries.map((country) => (
              <tr key={country.id}>
                <td>{country.name}</td>
                <td>
                  <button onClick={() => navigate(`/countries/edit/${country.id}`)}>
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </button>
                  <button onClick={() => handleDeleteCountry(country.id)}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        
        <h2>Monedas</h2>
        <button onClick={() => navigate('/currencies/new')}>
          <FontAwesomeIcon icon={faPlus} />
        </button>
        <br />
        <br />

        <table className={styles.table}>
          <thead>
            <tr>
              <th style={{ width: '60%' }}>Nombre</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {currencies.map((currency) => (
              <tr key={currency.id}>
                <td>{currency.name} ({currency.symbol})</td>
                <td>
                  <button onClick={() => navigate(`/currencies/edit/${currency.id}`)}>
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </button>
                  <button onClick={() => handleDeleteCurrency(currency.id)}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        
        <h2>Planes de subsidio</h2>
        <button onClick={() => navigate('/subsidy-plans/new')}>
          <FontAwesomeIcon icon={faPlus} />
        </button>
        <br />
        <br />

        <table className={styles.table}>
          <thead>
            <tr>
              <th style={{ width: '10%' }}>Código</th>
              <th style={{ width: '50%' }}>Nombre</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {subsidyPlans.map((subsidyPlan) => (
              <tr key={subsidyPlan.id}>
                <td>{subsidyPlan.code}</td>
                <td>{subsidyPlan.name}</td>
                <td>
                  <button onClick={() => navigate(`/subsidy-plans/edit/${subsidyPlan.id}`)}>
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </button>
                  <button onClick={() => handleDeleteSubsidyPlan(subsidyPlan.id)}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />

        <h2>Tipologías de Vivienda</h2>
        <button onClick={() => navigate('/housing-typologies/new')}>
          <FontAwesomeIcon icon={faPlus} />
        </button>
        <br />
        <br />

        <table className={styles.table}>
          <thead>
            <tr>
              <th style={{ width: '10%' }}>Código</th>
              <th style={{ width: '50%' }}>Nombre</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {housingTypologies.map((housingTypology) => (
              <tr key={housingTypology.id}>
                <td>{housingTypology.code}</td>
                <td>{housingTypology.name}</td>
                <td>
                  <button onClick={() => navigate(`/housing-typologies/edit/${housingTypology.id}`)}>
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </button>
                  <button onClick={() => handleDeleteHousingTypology(housingTypology.id)}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </div>
  );
}

export default ConfigForm;
