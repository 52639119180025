// src/services/TypologyServices.js
import axiosInstance from "./axiosConfig";

const API_URL = process.env.REACT_APP_API_URL;

// Eliminar una tipología
export const deleteTypology = async (id) => {
  try {
    const response = await axiosInstance.delete(`${API_URL}/typology/${id}`);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Obtener una tipología por su ID
export const getTypologyById = async (id) => {
  try {
    const response = await axiosInstance.get(`${API_URL}/typology/${id}`);
    if (!response.mediaItems)
      response.mediaItems = [];
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getTypologiesByStageId = async (stageId) => {
  try {
    const response = await axiosInstance.get(`${API_URL}/typologies/${stageId}`);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
}

// Actualizar una tipología existente
export const saveTypology = async (typology) => {
  try {
    const newTypology = {
      name: typology.name,
      code: typology.code,
      description: typology.description,
      slug: typology.slug,
      stageId: typology.stageId,
      bedrooms: typology.bedrooms,
      bathrooms: typology.bathrooms,
      targetFrame: typology.targetFrame,
      mediaItems: typology.mediaItems || []
    }
    if (typology.id) {
      newTypology.id = typology.id;
    }
    const response = await axiosInstance.put(`${API_URL}/typology`, newTypology);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};
