export const getMediaUrl = (object, reference) => {
  let items = object.mediaItems || [];
  for (let item of items) {
    if (item.reference === reference) {
      return item.url;
    }
  }
  return null;
};

export const getMeasureUnits = async () => {
  return [
    { name: "Metros cuadrados", code: "m²" },
    { name: "Pies cuadrados", code: "ft²" },
  ];
}