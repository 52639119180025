import React, { useEffect, useRef, useState } from "react";

const ProjectFilters = ({ project, onUnitsChange, onUnitChange }) => {
  const [activeTab, setActiveTab] = useState("stages");
  const [iframe, setIframe] = useState(null);
  const [loading, setLoading] = useState(true);
  const [stage, setStage] = useState(null);
  const stageRef = useRef(stage);
  const [typology, setTypology] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const ifr = document.getElementById("project-iframe");
      setIframe(ifr);

      window.addEventListener('message', (event) => {
        if (event.data.action === 'polygonSelected') {
          handleSelectPolygon(event.data.elementName);
        }
      });      

      setLoading(false);
    };

    fetchData();
  }, [project]);

  useEffect(() => {
    stageRef.current = stage;
  }, [stage]);  

  const getUnitStatus = (currentStatus, currentTypology) => {
    let filtered = [];
    let nameStatusPairs = [];
    let targetFrame = 1;
    stage.blocks.forEach((block) => {
      block.units.forEach((unit) => {
        let visible = true;
        if (currentStatus !== 'all' && unit.status !== currentStatus)
          visible = false;
        if (currentTypology && unit.typologySlug !== currentTypology)
          visible = false;
        const typologySlug = unit.typologySlug.split('-').slice(-1)[0];
        const unitCode = unit.code + "-" + typologySlug.charAt(0).toUpperCase() + typologySlug.slice(1);
        if (visible) {
          filtered.push(unitCode);
        }
        if (currentTypology==='kasa-1-k1c')
          targetFrame = 50;
        if (currentTypology==='kasa-1-k2a')
          targetFrame = 25;
        if (currentTypology==='kasa-1-k2b')
          targetFrame = 26;
        if (currentTypology==='kasa-1-k2c')
          targetFrame = 27;
        nameStatusPairs.push({ name: unitCode, status: unit.status });
      });
    });
    return { filtered: filtered, nameStatusPairs: nameStatusPairs, targetFrame: targetFrame };
  }

  const handleStageChange = (s) => {
    setStage(s);
    setActiveTab("typologies");
  }

  const handleSelectPolygon = (elementName) => {
    const unitCode = elementName.split("-")[0];
    const currentStage = stageRef.current;
    if (!currentStage) {
      return;
    }
    const unit = currentStage.blocks
      .map((block) => block.units)
      .flat()
      .find((unit) => unit.code === unitCode);
    onUnitChange(unit);
  };

  const handleSelectTypology = (t) => {
    let unitStatus = getUnitStatus("all", t.slug);
    const message = {
      action: "toggleSvg",
      status: true,
      filtered: unitStatus.filtered,
      nameStatusPairs: unitStatus.nameStatusPairs,
      targetFrame: unitStatus.targetFrame,
    };
    iframe.contentWindow.postMessage(message, iframe.src);
    setTypology(t);

    const units = [];
    stage.blocks.forEach((block) => {
      block.units.forEach((unit) => {
        if (unit.typologySlug === t.slug) {
          units.push(unit);
        }
      });
    });
    onUnitsChange(units);
  };

  const renderContent = () => {
    switch (activeTab) {
      case "stages":
        return <div>
            <h3>Seleccione etapa</h3>
            {project.stages.map((s) => (
                <div key={s.id} className="card" onClick={() => handleStageChange(s)}>
                <h4>{s.name}</h4>
                </div>
            ))}
        </div>;
      case "typologies":
        return <div>
          {stage && (
            <div>
              <div className="type-card header">
                  <h5>Tipología</h5>
                  <h5>Área Min</h5>
                  <h5>Área Máx</h5>
                  <h5>Unidades</h5>
                  <h5>Un. Disp.</h5>
              </div>
              {stage.typologies.map((t) => (
                <div 
                  key={t.id} 
                  className={`type-card ${typology && typology.id === t.id ? 'selected' : ''}`} 
                  onClick={() => handleSelectTypology(t)}
                >
                  <h5>{t.name}</h5>
                  <p>{t.buildAreaMin}</p>
                  <p>{t.buildAreaMax}</p>
                  <p>{t.totalUnits}</p>
                  <p>{t.totalAvailable}</p>
                </div>
              ))}
            </div>
          )}
          {!stage && <div>No ha seleccionado etapa</div>}
        </div>;
      default:
        return null;
    }
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div>
      <div className="tabs">
        <button
          className={activeTab === 'stages' ? 'active' : ''}
          onClick={() => setActiveTab('stages')}
        >
          Etapa
        </button>
        <button
          className={activeTab === 'typologies' ? 'active' : ''}
          onClick={() => setActiveTab('typologies')}
        >
          Tipología
        </button>
      </div>        
      <div className="tab-content-1">{renderContent()}</div>
    </div>
  );
};

export default ProjectFilters;
