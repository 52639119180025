// src/services/projectService.js
import axiosInstance from "./axiosConfig";

const API_URL = process.env.REACT_APP_API_URL;

// Crear un nuevo bloque
export const uploadFile = async (data) => {
  try {
    const response = await axiosInstance.post(`${API_URL}/upload-file`, data);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};
