// src/services/companyService.js
import axiosInstance from "./axiosConfig";

const API_URL = process.env.REACT_APP_API_CORE_URL;

export const getConfig = async () => {
  try {
    const response = await axiosInstance.get(`${API_URL}/config`);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Crear una nueva compañía
export const saveConfig = async (config) => {
  try {
    const response = await axiosInstance.put(`${API_URL}/config`, config);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

