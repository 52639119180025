// src/components/Sidebar.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebaseConfig';

function Sidebar() {
  const navigate = useNavigate();

  const handleLogout = async () => {
    await signOut(auth);
    navigate('/login');
  };

  return (
    <div className="sidebar">
      <div>
        <img src="/logo-owly.png" alt="Logo" className="sidebar-logo" />
      </div>
      <h2>OWLY SGI</h2>
      <ul>
        <li><Link to="/config">Datos Básicos</Link></li>
        <li><Link to="/companies">Compañías</Link></li>
        <li><Link to="/projects">Proyectos</Link></li>
        <li><Link to="/salesperson">Panel Vendedor</Link></li>
        <li><button onClick={handleLogout}>Salir</button></li>
      </ul>
    </div>
  );
}

export default Sidebar;
