import React, { useContext, useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { MediaContext } from '../context/MediaContext';
import { uploadFile } from '../services/FileServices';

const MediaItems = ( {projectSlug, objectType, objectSlug } ) => {
    const { mediaItems, addMedia, getMediaReference, updateMedia, deleteMedia } = useContext(MediaContext);
    const [error, setError] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [formData, setFormData] = useState({
        type: 'image',
        file: null,
        extension: '',
        url: ''
    });
    const [updateCounter, setUpdateCounter] = useState(0);

    const clearMedia = () => {
        setFormData({ type: 'image', file: null, extension: '', url: '' });
        setSelectedItem(null);
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            const extension = file.name.split('.').pop();
            setFormData({ ...formData, file: reader.result, extension: extension, url: '' });
          };
          reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setError(null);
        try {
            let reference = (selectedItem) ? selectedItem.reference : getMediaReference(formData.type);
            let uploadedUrl = formData.url;
            if (formData.file) {
                const data = {
                    projectSlug: projectSlug,
                    objectType: objectType,
                    objectSlug: objectSlug,
                    reference: reference,
                    fileContent: formData.file,
                    fileExtension: formData.extension,                
                }
                console.log(data);
                uploadedUrl = await uploadFile(data);  
            }
    
            const newItem = { 
                type: formData.type, 
                reference: reference,
                url: uploadedUrl
            };
            if (selectedItem) {
                updateMedia(selectedItem.reference, newItem);
            } else {
                addMedia(newItem);
            }
    
            setFormData({ type: 'image', file: null, extension: '', url: '' });
            setUpdateCounter(updateCounter + 1);
            setSelectedItem(null);    
        } catch (error) {
            setError(error.message);
        }
    };

    const handleEdit = (item) => {
        setSelectedItem(item);
        setFormData({ type: item.type, file: null, extension: '', url: item.url });
    };

    const handleDelete = (id) => {
        deleteMedia(id);
    };

    useEffect(() => {
    }, [updateCounter]);

    return (
        <div>
            <h2>Multimedia</h2>
            <div>
                <button onClick={() => clearMedia() }>
                    <FontAwesomeIcon icon={faPlus} />
                </button>
            </div>
            {error && <div className='error-message'>{error}</div>}

            <table>
                <tbody>
                {mediaItems.map(item => (
                    <tr key={item.reference}>
                        <td>{item.reference}</td>
                        <td>
                            <button onClick={() => handleEdit(item)}>
                                <FontAwesomeIcon icon={faPencilAlt} />
                            </button>
                            <button onClick={() => handleDelete(item.reference)}>
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <form onSubmit={handleSubmit}>
                <label>
                    Tipo:
                    <select name="type" value={formData.type} onChange={handleInputChange}>
                        <option value="image">Imagen</option>
                        <option value="rotator">Rotación 360°</option>
                        <option value="virtualTour">Tour Virtual</option>
                        <option value="isometric">Isometría</option>
                        <option value="plans">Planos</option>
                        <option value="measurements">Medidas</option>
                        <option value="floorPlan">Planta</option>
                        <option value="view">Vista</option>
                        <option value="other">Otro</option>
                    </select>
                </label>
                <label>
                    Seleccione un archivo:
                    <input type="file" onChange={handleFileChange} />
                </label>
                <br/>
                <label>
                    URL:
                    <input type="text" name="url" value={formData.url} onChange={handleInputChange} disabled={formData.file !== null} />
                </label>
                <button type="submit">Enviar</button>
            </form>
        </div>
    );
};

export default MediaItems;