// src/services/HousingTypologyServices.js
import axiosInstance from "./axiosConfig";

const API_URL = process.env.REACT_APP_API_CORE_URL;

export const getHousingTypologies = async () => {
  try {
    const response = await axiosInstance.get(`${API_URL}/housingTypologies`);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Obtener una tipología de vivienda por su ID
export const getHousingTypologyById = async (id) => {
  try {
    const response = await axiosInstance.get(`${API_URL}/housingTypology/${id}`);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Eliminar una tipología de vivienda
export const deleteHousingTypology = async (id) => {
  try {
    const response = await axiosInstance.delete(`${API_URL}/housingTypology/${id}`);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Crear una nueva tipología de vivienda
export const saveHousingTypology = async (housingTypology) => {
  try {
    const response = await axiosInstance.put(`${API_URL}/housingTypology`, housingTypology);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};
