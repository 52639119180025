// src/pages/CompanyForm.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { getCompanyById, createCompany, updateCompany } from '../services/CompanyServices';
import './Forms.css';
import { getCountries } from '../services/CountryServices';

function CompanyForm() {
  const { id } = useParams();
  const [company, setCompany] = useState({ name: '', country: '', slug: '' });
  const [countries, setCountries] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const countries = await getCountries(); // Usar el servicio para obtener países
        setCountries(countries);
        if (id) {
          const companyData = await getCompanyById(id); // Usar el servicio para obtener compañía
          setCompany(companyData);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchCompany();
  }, [id]);

  const handleNameChange = (e) => {
    const newName = e.target.value;
    const newSlug = newName.toLowerCase().replace(/\s+/g, '-'); // Generar el slug a partir del nombre
    setCompany({ ...company, name: newName, slug: newSlug });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        await updateCompany(id, company); // Usar el servicio para actualizar compañía
      } else {
        await createCompany(company); // Usar el servicio para crear una nueva compañía
      }
      navigate('/companies'); // Redirigir a la lista de compañías
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <p>Cargando...</p>;
  }

  return (
    <div className="form-container">
      {error && (
      <div>
        <div className='error-message'>
          Hubo un error: {error}
        </div>
        <br/>
      </div>
      )}
      <h2>{id ? 'Editar Compañía' : 'Nueva Compañía'}</h2>
      <form onSubmit={handleSubmit} className="company-form">
        <div className="form-group">
          <label htmlFor="company-name">Nombre</label>
          <input
            type="text"
            id="company-name"
            value={company.name}
            onChange={handleNameChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="company-country">País</label>
          <select
            id="company-country"
            value={company.country}
            onChange={(e) => setCompany({ ...company, country: e.target.value })}
            required
          >
            <option value="">Seleccione un país</option>
            {countries.map((country) => (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="company-slug">Slug (auto-generado)</label>
          <input
            type="text"
            id="company-slug"
            value={company.slug}
            readOnly
          />
        </div>

        <div className="form-group">
          <button type="submit" className="btn-submit">{id ? 'Guardar Cambios' : 'Agregar Compañía'}</button>
        </div>
      </form>
      <div className="form-footer">
        <Link to="/companies" className="back-link">Regresar a Compañías</Link>
      </div>
    </div>
  );
}

export default CompanyForm;
