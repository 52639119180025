// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebaseConfig';

import AdditionalTypeForm from './pages/AdditionalTypeForm';
import BlockForm from './pages/BlockForm';
import Companies from './pages/Companies';
import CompanyForm from './pages/CompanyForm';
import CityForm from './pages/CityForm';
import ConfigForm from './pages/ConfigForm';
import CountryForm from './pages/CountryForm';
import CurrencyForm from './pages/CurrencyForm';
import ForgotPassword from './pages/ForgotPassword'; 
import HousingTypologyForm from './pages/HousingTypologyForm';
import Login from './pages/Login';
import { MediaProvider } from './context/MediaContext';
import Projects from './pages/Projects';
import ProjectForm from './pages/ProjectForm';
import Salesperson from './pages/Salesperson';
import Sidebar from './components/Sidebar';
import StageForm from './pages/StageForm';
import SubsidyPlanForm from './pages/SubsidyPlanForm';
import TypologyForm from './pages/TypologyForm';
import UnitForm from './pages/UnitForm';

import './App.css';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);  // Estado de carga inicial

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user ? user : null);
      setLoading(false);  // Terminamos la carga cuando Firebase responde
    });
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div className="loading-screen">Cargando...</div>;  // Mostrar pantalla de carga
  }

  return (
    <Router>
      <MediaProvider>        
      <div className={`App ${user ? 'app-with-sidebar' : ''}`}>
        {user && <Sidebar />}
        <div className="main-content">
          <Routes>
            <Route path="/forgot-password" element={<ForgotPassword />} />

            {!user ? (
              <>
                <Route path="*" element={<Login />} />
              </>
            ) : (
              <>
                <Route path="/additionalTypes/edit/:stageId/:id" element={<AdditionalTypeForm />} />
                <Route path="/additionalTypes/new/:stageId" element={<AdditionalTypeForm />} />
                <Route path="/blocks/edit/:stageId/:id" element={<BlockForm />} />
                <Route path="/blocks/new/:stageId" element={<BlockForm />} />
                <Route path="/cities/new/:countryId" element={<CityForm />} />
                <Route path="/cities/edit/:countryId/:id" element={<CityForm />} />
                <Route path="/companies" element={<Companies />} />
                <Route path="/companies/new" element={<CompanyForm />} />
                <Route path="/companies/edit/:id" element={<CompanyForm />} />                
                <Route path="/config" element={ <ConfigForm /> } />
                <Route path="/countries/new" element={<CountryForm />} />
                <Route path="/countries/edit/:id" element={<CountryForm />} />                
                <Route path="/currencies/new" element={<CurrencyForm />} />
                <Route path="/currencies/edit/:id" element={<CurrencyForm />} />                
                <Route path="/housing-typologies/edit/:id" element={<HousingTypologyForm />} />
                <Route path="/housing-typologies/new" element={<HousingTypologyForm />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/projects/new" element={<ProjectForm />} />
                <Route path="/projects/edit/:id" element={<ProjectForm />} />
                <Route path="/stages/new/:projectId" element={<StageForm />} />
                <Route path="/stages/edit/:projectId/:id" element={<StageForm />} />
                <Route path="/salesperson" element={<Salesperson />} />
                <Route path="/subsidy-plans/new" element={<SubsidyPlanForm />} />
                <Route path="/subsidy-plans/edit/:id" element={<SubsidyPlanForm />} />                
                <Route path="/typologies/edit/:stageId/:id" element={<TypologyForm />} />
                <Route path="/typologies/new/:stageId" element={<TypologyForm />} />
                <Route path="/units/edit/:blockId/:id" element={<UnitForm />} />
                <Route path="/units/new/:blockId" element={<UnitForm />} />
                <Route path="*" element={<Navigate to="/projects" />} />
              </>
            )}
          </Routes>
        </div>
      </div>
      </MediaProvider>
    </Router>
  );
}

export default App;
