// src/services/subsidyPlanService.js
import axiosInstance from "./axiosConfig";

const API_URL = process.env.REACT_APP_API_CORE_URL;

export const getSubsidyPlans = async () => {
  try {
    const response = await axiosInstance.get(`${API_URL}/subsidyPlans`);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Obtener un plan de subsidio por su ID
export const getSubsidyPlanById = async (id) => {
  try {
    const response = await axiosInstance.get(`${API_URL}/subsidyPlan/${id}`);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Eliminar un plan de subsidio
export const deleteSubsidyPlan = async (id) => {
  try {
    const response = await axiosInstance.delete(`${API_URL}/subsidyPlan/${id}`);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Crear un nuevo plan de subsidio
export const saveSubsidyPlan = async (subsidyPlan) => {
  try {
    const response = await axiosInstance.put(`${API_URL}/subsidyPlan`, subsidyPlan);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};
