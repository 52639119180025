import React, { createContext, useState } from 'react';

export const MediaContext = createContext();

export const MediaProvider = ({ children }) => {
  const [mediaItems, setMediaItems] = useState([]);

  const addMedia = (media) => {
    setMediaItems((prevMediaItems) => {
      return [...prevMediaItems, media];
    });
  }

  const deleteMedia = (mediaReference) => {
    setMediaItems((prevMediaItems) => {
      return prevMediaItems.filter((media) => media.reference !== mediaReference);
    });
  };

  const getMediaReference = (mediaType) => {
    let counter = 0;
    mediaItems.forEach((item) => {
      if (item.type === mediaType) {
        counter++;
      }
    });
    counter++;
    return `${mediaType}-${counter}`;
  }

  const updateMedia = (mediaReference, updatedMedia) => {
    setMediaItems((prevMediaItems) => {
      const updatedMediaItems = prevMediaItems.map((media) => {
        if (media.reference === mediaReference) {
          return updatedMedia;
        }
        return media;
      });

      return updatedMediaItems;
    });
  }

  return (
    <MediaContext.Provider value={{ 
      mediaItems,
      addMedia,
      deleteMedia,
      getMediaReference,
      updateMedia,
      setMediaItems
    }}>
      {children}
    </MediaContext.Provider>
  );
};
