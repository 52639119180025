// src/services/projectService.js
import axiosInstance from "./axiosConfig";

const API_URL = process.env.REACT_APP_API_URL;

export const getLeads = async (projectId) => {
  try {
    const response = await axiosInstance.get(`${API_URL}/leads-by-project/${projectId}`);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

