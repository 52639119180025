// src/pages/Projects.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deleteProject, getProjects } from '../services/ProjectServices';
import { faPlus, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import styles from './Table.module.css';

function Projects() {
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updateCounter, setUpdateCounter] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const lista = await getProjects();
        setProjects(lista);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, [updateCounter]);

  const handleDelete = async (id) => {
    setError(null);
    setLoading(true);
    try {
      await deleteProject(id);
      setUpdateCounter(updateCounter + 1);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Cargando...</div>; 
  }

  return (
    <div>
      <h2>Proyectos</h2>
      {error && (
        <div>
          <div className='error-message'>
            Hubo un error: {error}
          </div>
          <br/>
        </div>
      )}
      <button onClick={() => navigate('/projects/new')}>
        <FontAwesomeIcon icon={faPlus} /> {/* Ícono de "+" */}
      </button>
      <br/>
      <br/>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Descripción</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {projects.map((project) => (
            <tr key={project.id}>
              <td>{project.name}</td>
              <td>{project.description}</td>
              <td>
                <button onClick={() => navigate(`/projects/edit/${project.id}`)}>
                  <FontAwesomeIcon icon={faPencilAlt} />
                </button>
                <button onClick={() => handleDelete(project.id)}>
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Projects;
