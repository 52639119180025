export const messages_es = {
    "ERR.ADDITIONAL_TYPE.NOT_FOUND": "Tipo adicional no encontrado",
    "ERR.ADDITIONAL_TYPE.USED": "No se puede eliminar el tipo adicional porque está en uso",
    "ERR.BLOCK.NOT_FOUND": "Bloque no encontrado",
    "ERR.BLOCK.USED": "No se puede eliminar el bloque porque está en uso",
    "ERR.COMPANY.USED": "No se puede eliminar la compañía porque está en uso",
    "ERR.COUNTRY.USED": "No se puede eliminar el país porque está en uso",
    "ERR.FILE.UPLOAD": "Error al subir el archivo",
    "ERR.LEAD.NOT_FOUND": "Lead no encontrado",
    "ERR.PROJECT.NOT_FOUND": "Proyecto no encontrado",
    "ERR.PROJECT.USED": "No se puede eliminar el proyecto porque está en uso",
    "ERR.QUOTE.NOT_FOUND": "Cotización no encontrada",
    "ERR.STAGE.NOT_FOUND": "Etapa no encontrada",
    "ERR.STAGE.USED": "No se puede eliminar la etapa porque está en uso",
    "ERR.TYPOLOGY.NOT_FOUND": "Tipología no encontrada",
    "ERR.TYPOLOGY.USED": "No se puede eliminar la tipología porque está en uso",
    "ERR.UNIT.NOT_FOUND": "Unidad no encontrada",
    "ERR.UNIT.USED": "No se puede eliminar la unidad porque está en uso"
}
