// src/pages/Companies.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deleteCompany, getCompanies } from '../services/CompanyServices';
import { faPlus, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import styles from './Table.module.css';

function Companies() {
  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updateCounter, setUpdateCounter] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        setError(null);
        const list = await getCompanies();
        setCompanies(list);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchCompanies();
  }, [updateCounter]);

  const handleDelete = async (id) => {
    setError(null);
    setLoading(true);
    try {
      await deleteCompany(id);
      setUpdateCounter(updateCounter + 1);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Cargando...</div>; 
  }

  return (
    <div>
      <h2>Compañías</h2>
      {error && (
        <div>
          <div className='error-message'>
            Hubo un error: {error}
          </div>
          <br/>
        </div>
      )}
      <button onClick={() => navigate('/companies/new')}>
        <FontAwesomeIcon icon={faPlus} /> {/* Ícono de "+" */}
      </button>
      <br/>
      <br/>
      <table className={styles.table}>
        <thead>
          <tr>
            <th style={{ width: '70%' }}>Nombre</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {companies.map((company) => (
            <tr key={company.id}>
              <td>{company.name}</td>
              <td>
                <button onClick={() => navigate(`/companies/edit/${company.id}`)}>
                  <FontAwesomeIcon icon={faPencilAlt} />
                </button>
                <button onClick={() => handleDelete(company.id)}>
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Companies;
