// src/pages/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig';
import './ForgotPassword.css';  // Importar estilos específicos del formulario de login
import { sendPasswordResetEmail } from 'firebase/auth';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      await sendPasswordResetEmail(auth,email);
      alert('Se ha enviado un correo para restablecer la contraseña.');
      navigate('/login');
    } catch (error) {
      console.error(error);
    }
  };

  const handleLogin = () => {
    navigate('/login');  
  };

  return (
    <div className="forgot-password-container">
      <div className="forgot-password-box">
        <img src="/logo-owly.png" alt="Logo" className="logo" />  {/* Logo en la parte superior */}
        <h2>Recuperar contraseña</h2>
        {error && (
          <div>
            <div className='error-message'>
              Hubo un error: {error}
            </div>
            <br />
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <input
              type="email"
              placeholder="Email"
              id="forgot-password-email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
              required
            />
          </div>
          <button type="submit" className="forgot-password-button">Enviar</button>
        </form>
        <button className="forgot-password-link" onClick={handleLogin}>
          Regresar al inicio de sesión
        </button>
      </div>
    </div>
  );
}

export default ForgotPassword;
